import { render, staticRenderFns } from "./WapRHD008Operate.vue?vue&type=template&id=3841a47d&"
import script from "./WapRHD008Operate.vue?vue&type=script&lang=js&"
export * from "./WapRHD008Operate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports