<template>
    <div>
        <el-alert :title="connectStatus" v-if="connectStatus" center show-icon type="error" :closable="false">
        </el-alert>
        <div v-if="active == 0"><van-empty description="暂不支持页面操作" /></div>
        <div v-else>
            <van-cell-group>
                <van-field v-model="wenDuZhi" type="number" label="温度值" placeholder="请输入温度值" />
                <van-field v-model="xiuZhengZhi" type="number" label="修正值" placeholder="请输入修正值" />
            </van-cell-group>
            <van-row type="flex" justify="space-around" style="margin-top: 5px;margin-bottom: 5px;">
                <van-button type="primary" @click="sendCMD42">保存标定点</van-button>
                <van-button type="info" @click="sendCMD41">获取标定点</van-button>
                <van-button type="danger" @click="sendCMD43">清除标定点</van-button>
            </van-row>
            <van-cell-group inset>
                <van-cell v-for="(item, index) in biaoDingList" :key="index" :label="'温度：' + item[0] / 10.0" title="修正值" :value="item[1] / 10.0" />
            </van-cell-group>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
            <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
    props: ["serialNumber"],
    data() {
        return {
            connectStatus: "",
            active: 1,
            websock: null,
            mtf: {},
            biaoDingList: [],
            wenDuZhi: null,
            xiuZhengZhi: null
        };
    },
    methods: {
        sendCMD41() {
            let that = this;
            that.websock.send(
                JSON.stringify({
                    serialNumber: that.serialNumber,
                    command: "41",
                })
            );
        },
        sendCMD42() {
            let that = this;
            if (that.wenDuZhi && that.xiuZhengZhi) {
                that.websock.send(
                    JSON.stringify({
                        serialNumber: that.serialNumber,
                        command: "42",
                        data: that.wenDuZhi + "," + that.xiuZhengZhi
                    })
                );
            }
            else {
                that.$notify({ type: "warning", message: "有空值" });
            }

        },
        sendCMD43() {
            let that = this;
            that.$dialog.confirm({ title: "提示", message: "确定要清除吗？" }).then(() => {
                that.websock.send(
                    JSON.stringify({
                        serialNumber: that.serialNumber,
                        command: "43",
                    })
                );
            });
        }
    },
    mounted() {
        let that = this;
        document.title = "软化点";
        //初始化webSocket
        const wsuri = process.env.VUE_APP_WebSocketURL1;
        that.websock = new WebSocket(wsuri);

        that.websock.onmessage = function (e) {
            //数据接收
            that.connectStatus = "";
            that.mtf = JSON.parse(e.data);

            if (that.mtf.code == 101) {
                if (that.mtf.cmd == "41") {
                    that.biaoDingList = that.mtf.data.biaoDingList;
                }
                if (that.mtf.cmd == "43") {
                    that.biaoDingList = [];
                }
            } else {
                that.connectStatus = "未连接,请配置设备WIFI并连上网络";
            }

            setTimeout(() => {
                that.websock.send(
                    JSON.stringify({
                        serialNumber: that.serialNumber,
                        command: "32",
                    })
                );
            }, 300);
        };
        that.websock.onopen = function () {
            that.websock.send(
                JSON.stringify({
                    serialNumber: that.serialNumber,
                    command: "32",
                })
            );
        };
        that.websock.onerror = function () {
            that.connectStatus = "请刷新页面";
        };
        that.websock.onclose = function () {
            that.connectStatus = "请刷新页面";
        };
    }
};
</script>

<style></style>